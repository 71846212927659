@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

*{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body, html{
  margin: 0;
  padding: 0;
}

body{
  background: #fafafa;
  font-family: 'Montserrat', sans-serif;
}

.wrapper{
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container{
  width: 900px;
}

header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 900px;
}

#logo{
  max-width: 100%;
  height: auto;
}

.logo-wrapper a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.logo-wrapper img{
  width: 70px;
  margin-left: -10px;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

nav{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

nav a{
  color: #222;
  opacity: .8;
  text-decoration: none;
  font-weight: 700;
  margin-right: 32px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  position: relative;
}

nav a:after{
  display: none;
  height: 4px;
  position: absolute;
  bottom: -8px;
  content: '';
  background: #05CE91;
  width: 100%;
}

nav a:hover:after{
  display: block;
}

.key{
  margin-left: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.mobile-form{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px;
  background: white;
  border-bottom: 1px solid rgba(0,0,0, .08);
}

.backdrop{
  background: rgba(0,0,0, .6);
  flex: 1;
}

.login-form{
  padding: 24px;
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: white;
  position: absolute;
  top: 54px;
  right: 0;
  z-index: 1;
  border: 1px solid rgb(241, 241, 241);
}

.mobile-login-form{
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
          height: calc(100vh - 49px);
          max-height: calc(100vh - 49px);
          width: 100vw;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  top: 49px;
}
.mobile-login-form input{
  margin-bottom: 24px;
  border: 0;
  border: 1px solid rgb(241, 241, 241);
  background: rgb(241, 241, 241);
  outline: none !important;
  -webkit-appearance:none;
  -moz-appearance:none;
  font-size: 18px;
  border-radius: 0 !important;
  font-family: 'Montserrat', sans-serif;
  padding: 12px;
}
.mobile-login-form .mobile-form button{
  font-size: 18px;
  outline: none;
  border: none;
  font-family: 'Montserrat', sans-serif;
  height: 46px;
  cursor: pointer;
  background: #05CE91;
  color: #00150f;
  width: 100px;
}
.mobile-login-form .mobile-form button.primary-btn{
  background: #05CE91;
  color: #00150f;
}

.mobile-login-form .mobile-form button.secondary-btn{
  color: rgb(31, 31, 31);
  margin-right: 24px;
  background: white;
  /* border: 1px solid rgb(31, 31, 31); */
}

form{
  display: flex;
  flex-direction: column;
}

.login-form::before{
  content: '';
  width: 24px;
  height: 24px;
  background: white;
  border-top: 1px solid rgb(241, 241, 241);
  border-left: 1px solid rgb(241, 241, 241);
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: -13px;
  right: 4px;
}

.login-form input{
  /* background: rgb(241, 241, 241); */
  border: 0;
  border: 1px solid rgb(241, 241, 241);
  background: rgb(241, 241, 241);
  outline: none;
  margin-bottom: 24px;
  font-size: 18px;
  height: 50px;
  font-family: 'Montserrat', sans-serif;
  padding: 12px;
}

.login-form button{
  font-size: 18px;
  outline: none;
  border: none;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 12px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 50px;
  cursor: pointer;
}

.login-form button.primary-btn{
  background: #05CE91;
  color: #00150f;
}

.login-form button.secondary-btn{
  color: rgb(31, 31, 31);
  margin-right: 24px;
  background: white;
  /* border: 1px solid rgb(31, 31, 31); */
}

.login-form button.secondary-btn:hover{
  background:rgb(245, 245, 245);
}

.login-form button.primary-btn:hover{
  background: rgb(3, 220, 155);
}

.social span{
  margin-right: 16px;
  font-size: 16px;
  font-weight: 600;
}

.dot{
  color: #05CE91;
}

.social a, .mail-container a{
  text-decoration: none;
  color: whitesmoke;
}

.social a:hover, .mail-container a:hover{
  text-decoration: underline;
}

.form-actions{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.home-top-section{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 6fr 24px 5fr;
  grid-template-columns: 6fr 5fr;
  -ms-grid-rows: 2fr 24px 2fr;
  grid-template-rows: repeat(2, 2fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin-bottom: 24px;
}

#me{
  width: 100%;
  height: auto;
}

.home-top-section > *:nth-child(1){
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.home-top-section > *:nth-child(2){
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.home-top-section > *:nth-child(3){
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.home-top-section > *:nth-child(4){
  -ms-grid-row: 3;
  -ms-grid-column: 3;
}

.mail-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding-left: 12px;
}

.mail-container .mail{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.mail-container .mail svg{
  margin-right: 16px;
}

.portrait-container{
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-area: 1 / 1 / 3 / 2; 
  position: relative;
}

.name-box{
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 24px;
  left: 0;
  top: 0;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: none;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.portrait-container img{
  max-width: 100%;
  /* height: auto; */
  height: 100%;
  vertical-align: middle;
}

.name-container{
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 1 / 2 / 2 / 3; 
  background: #05CE91;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.social-container{
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-area: 2 / 2 / 3 / 3; 
  background: rgb(31, 31, 31);
  color: whitesmoke;
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.centered{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 32px;
}

.firstname{
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.lastname{
  font-size: 16px;
  font-weight: 700;
  opacity: .7;
  text-transform: uppercase;
}

section{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* background: white; */
  width: 900px;
  padding: 48px;
  margin-bottom: 32px;
}

section a{
  color: #222;
  font-weight: 600;
}

section a:hover{
  text-decoration: none;
}

.section-heading{
  width: 200px;
  padding-right: 24px;
}

h2{
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 6px;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
}

h2:after{
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 4px;
  width: 50%;
  background: #05CE91;
  content: '';
}

p{
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 24px;
  margin-top: 0;
  font-weight: 500;
  color: #222;
  opacity: .9;
}

section p:last-child{
  margin-bottom: 0;
}

.section-info{
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.knowledge-items{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.knowledge-item{
  background: #fafafa;
  padding: 16px !important;
  margin-right: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #222;
  /* border-radius: 4px; */
}

.projects{
  width: 900px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 32px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.project{
  width: calc(50% - 12px);
  margin-bottom: 32px;
  position: relative;
}

.project img{
  width: 100%;
  height: auto;
  border: 8px solid white;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0, .08);
          box-shadow: 0 0 0 1px rgba(0,0,0, .08);
}

.project:nth-child(odd){
  margin-right: 24px;
}

.project-title a{
  font-size: 18px;
  font-weight: 600;
  padding-left: 8px;
  padding-top: 8px;
  color: #222;
  display: inline-block;
  text-decoration: none;
}

.project-title a:hover, .project:hover .project-title a{
  text-decoration: underline;
}

@keyframes shine{
  to {
    background-position: 100% 0, 0 0;
  }
}

.skeleton{
  height: 210px;
  width: calc(50% - 12px);
  margin-bottom: 48px;
  margin-left: 6px;
  margin-right: 6px;
}

.skeleton-project-image{
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,
  rgba(255, 255, 255, 0),
  rgba(255, 255, 255, 0.4) 50%,
  rgba(255, 255, 255, 0) 80%
  ),
#e6e6e6;
border: 1px solid #efefef;
  background-repeat: repeat-y;
  background-size: 800px 400px;
  background-position: 0 0; 
  animation: shine 1s infinite;
}

.skeleton-project-title{
  width: 200px;
  background: green;
  height: 16px;
  margin-top: 12px;
  background: linear-gradient(to right,
  rgba(255, 255, 255, 0),
  rgba(255, 255, 255, 0.4) 50%,
  rgba(255, 255, 255, 0) 80%
  ),
#e6e6e6;
border: 1px solid #efefef;
  background-repeat: repeat-y;
  background-size: 800px 400px;
  background-position: 0 0; 
  animation: shine 1s infinite;
}

.mobile-nav, .hamburger{
  display: none;
}

.hamburger, .mobile-key{
  cursor: pointer;
}

.mobile-navigation{
  display: none;
}

.mobile-key{
  width: 36px;
  height: 36px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #222;
  justify-content: center;
}

.mobile-key svg{
  width: 22px;
  height: 22px;
}

.white{
  background: white;
}

.green{
  background: #05CE91;
  color: #00150f;
}

.green h2:after{
  background: rgba(31, 31, 31, .4);
}

.black{ background: rgb(31, 31, 31); }

.black p, .black h2{ color: whitesmoke; }

.black a{
  color: #05CE91;
}

span.close:first-child{
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 24px;
}

span.close:last-child{
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.separator{
  width: 72px;
  height: 1px;
  margin-top: 12px;
  display: inline-block;
  margin-bottom: 32px;
  background: lightgrey;
}

form#contactform{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form-field{
  padding-bottom: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

form#contactform input, textarea{
  background: rgb(241, 241, 241);
  border: 0;
  outline: none;
  font-size: 18px;
  border-radius: 0 !important;
  font-family: 'Montserrat', sans-serif;
  padding: 12px;
  -webkit-appearance:none;
  -moz-appearance:none;
}

form#contactform input{
  width: 100%;
  height: 50px;
}

textarea{
  width: 100%;
  resize: none;
  height: 200px;
}

.form-inputs{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.form-inputs .form-field:first-child{
  padding-right: 24px;
}

form#contactform .form-actions button{
  font-size: 18px;
  outline: none;
  border: none;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 12px;
  height: 50px;
  cursor: pointer;
  background: #05CE91;
  color: #00150f;
  width: 100px;
}

form#contactform button:hover{
  background: rgb(3, 220, 155);
}

::-moz-selection{
  background: #05CE91;
  color: #00150f;
}

::selection{
  background: #05CE91;
  color: #00150f;
}

.error-border{
  border: 1px solid red !important;
}

.error-msg{
  color: #ff7675 !important;
}

.contact-social-links a{
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
}

.contact-social-links a:hover{
  text-decoration: underline;
}

.contact-social-links svg{
  margin-right: 12px;
}

.contact-top-section{
  background: #05CE91;
  padding-top: 32px;
  padding-left: 24px;
  margin-bottom: 32px;
}

.contact-avatar-wrapper{

}

.contact-info-section{
  position: relative;
}

#avatar{
  max-width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

.response-box{
  display: flex;
  justify-content: space-between !important;
}

.form-msg{
  color: whitesmoke;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.form-msg svg{
  margin-right: 12px;
}

.form-response{
  display: flex;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 948px) {
  .container, header, section, .projects{
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .name-container, .social-container{
    display: none;
  } 
  section{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 24px;
    padding-bottom: 32px;
    padding-top: 24px;
  }
  .section-heading{
    margin-bottom: 32px;
  }
  .home-top-section{
    display: unset;
  }
  #me{
  margin-bottom: 24px;
  }
  header{
    padding-bottom: 12px;
  }
  .name-box{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: linear-gradient(160deg, transparent 40%, rgba(0,0,0,1));
    margin-bottom: 24px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
  .name-box span:first-child{
    font-size: 24px;
    line-height: 32px;
    color: #05CE91;
  }
  .name-box span{
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: whitesmoke;
  }
  .mob-mb-12{
    margin-bottom: 12px;
  }
  .hide-heading{
    display: none;
  }
  .contact-info-section{
    padding-top: 32px;
  }
}

@media screen and (max-width: 700px) {
  .project, div.skeleton{
    width: 100%;
  }
  .project:nth-child(odd), div.skeleton:nth-child(odd){
    margin-right: 0;
  }
  header{
    position: sticky;
    /* -webkit-backdrop-filter: blur(200px);
            backdrop-filter: blur(200px); */
            background: white;
    top: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 6px;
    padding-top: 6px;
    border-bottom: 1px solid rgba(0,0,0, .08);
  }
  .logo-wrapper img{
    width: 40px;
    margin-left: -6px;
  }
  .key{
    margin-left: 12px;
  }
  .portrait-container img{
    height: auto;
  }
  .container:not(.info-container), .projects{
    padding-top: 24px;
  }
  #me, section{
    margin-bottom: 0;
  }
  section{
    padding-bottom: 12px;
  }
  section.mob-pb-48{
    padding-bottom: 48px;
  }
  .projects{
    padding-bottom: 0;
  }
  .name-box{
    margin-bottom: 0;
  }
  .skeleton{
    height: 310px;
    margin-left: 0;
    margin-right: 0;
  }
  .section-tb-48{
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .black, .white{
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .green{
    padding-top: 32px;
    padding-bottom: 24px;
  }
  .form-inputs{
    flex-direction: column;
  }
  .form-field{
    width: 100%;
  }
  .form-inputs .form-field:first-child{
    padding-right: 0 !important;
  }
}

  /* @media screen and (max-width: 480px) { */
  @media screen and (max-width: 600px) {
    .mobile-nav, .hamburger{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .mobile-navigation{
      display: flex;
    }
    .mobile-nav{
      position: absolute;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      height: calc(100vh - 49px);
      max-height: calc(100vh - 49px);
      width: 100vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      left: 0;
      top: 49px;
      padding-left: 24px;
      background: #05CE91;

    }
    .links{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
    .links a{
      font-size: 32px;
      margin-bottom: 12px;
      width: 200px;
      text-decoration: none;
      font-weight: 700;
      color: #00150f;
    }
    .mob-social-links{
      padding-bottom: 12px;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
    }

    .mob-social-links a{
      color: #00150f;
      text-decoration: none;
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      width: 200px;
    }

    .mob-social-links a span svg{
      margin-right: 24px;
    }    

    .hamburger{
      width: 36px;
      height: 36px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    .hamburger span:nth-child(2){
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .hamburger span{
      width: 100%;
      height: 2px;
      width: 24px;
      background: #222;
    }
    nav{
      display: none;
    }
    .container{
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    header{
      padding-right: 18px;
    }
    .project-title a{
      font-size: 16px;
      padding-left: 0;
    }
    .name-box{
      padding-bottom: 24px;
      padding-right: 24px;
    }
    .name-box span:first-child{
      font-size: 16px;
      line-height: 16px;
      color: #05CE91;
    }
    .name-box span{
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      color: whitesmoke;
    }
    .skeleton{
      height: 240px;
    }
  }
  

  @media screen and (max-width: 420px) {
  h2{
    font-size: 18px;
  }
  p{
    font-size: 16px;
    line-height: 30px;
  }
  .contact-social-links a{
    font-size: 16px !important;
    line-height: 16px;
  }
  .knowledge-item{
    padding: 8px !important;
    font-weight: 600;
    font-size: 14px;
    width: calc(50% - 8px);
  }
  .knowledge-item:nth-child(even){
    margin-right: 0;
  }
  .project-title a:hover, .project:hover .project-title a{
    text-decoration: none;
  }
  .skeleton{
    height: 160px;
  }
  .form-msg{
    font-size: 16px;
    line-height: 16px;
  }
  .form-msg svg{
    display: none;
  }
  #avatar{
    display: none;
  }
}